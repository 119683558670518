@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("http://nftpass.xyz/background.png");
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transparent {
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s;
}

.transparent:hover{
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.7rem;
}

.pepe {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 30%;
}

@media only screen and (min-width: 992px) {
  .pepe {
      max-width: 50%;
  }
}

@media only screen and (min-width: 192px) {
  .navbar {
    background-color: white;
    border-radius: 1rem;
  }
}

@media only screen and (min-width: 1400px) {
  .pepe {
      max-width: 35%;
  }
}
.colored {
    background: linear-gradient(
        to right,
        rgba(30, 150, 250, 0.7),
        rgba(200, 30, 200, 0.7)
    );
}
.coloredreverse {
    background: linear-gradient(
        to right,
        rgba(200, 30, 200, 0.7),
        rgba(30, 150, 250, 0.7)
    );
}

.transparent {
    background-color: rgba(0, 0, 0, 0);
}

.imagecenter {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
    text-align: center;
    min-width: 100%;
}

.pepe {
    position: fixed;
    bottom: 0;
    right: 0;
    max-width: 30%;
}

@media only screen and (min-width: 992px) {
    .pepe {
        max-width: 50%;
    }
}

@media only screen and (min-width: 1400px) {
    .pepe {
        max-width: 35%;
    }
}

.hidden {
    display: none;
}

.score-breakdown {
    background-color: white;
    border-radius: 0%;
    height: 330px;
    overflow-y: scroll;
    width:350px;
    padding:30px;
}

.score-component {
    position: relative;
    display: inline;
    font-size: 12px;
    margin-bottom:10px;
    display: block;
    color:

}

.score-breakdown-title
{
    border-bottom: 1px solid black;
}

.see-leaderboard
{
    text-align:center;

}

.points-piece
{
    color: #FF48F8;
}

.points-collection
{
    color: #00B19C;
}

.score-component .points
{
    position: relative;
    display: inline-block;
    float: right;
    font-weight:bold;
}

.score-component .score-component-text
{
    width: 230px;
    text-overflow: ellipsis;
    display: inline-block;
}

.points {
    position: relative;
    display: inline;
    text-align: right;
}


table
{
    background-color:white;
    margin-top:50px;
    th
    {
        width:150px;
    }
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid black;
}

.user-address
{
    color:blue;
}

.percentile-row
{
    font-size:20px;
}
