@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

.colored {
    background: linear-gradient(
        to right,
        rgba(30, 150, 250, 0.7),
        rgba(200, 30, 200, 0.7)
    );
}
.coloredreverse {
    background: linear-gradient(
        to right,
        rgba(200, 30, 200, 0.7),
        rgba(30, 150, 250, 0.7)
    );
}

.transparent {
    background-color: rgba(0, 0, 0, 0);
}

.imagecenter {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
    text-align: center;
    min-width: 100%;
}

.pepe {
    position: fixed;
    bottom: 0;
    right: 0;
    max-width: 30%;
}

@media only screen and (min-width: 992px) {
    .pepe {
        max-width: 50%;
    }
}

@media only screen and (min-width: 1400px) {
    .pepe {
        max-width: 35%;
    }
}

.hidden {
    display: none;
}
