table
{
    background-color:white;
    margin-top:50px;
    th
    {
        width:150px;
    }
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid black;
}

.user-address
{
    color:blue;
}

.percentile-row
{
    font-size:20px;
}